<template>
  <div class="main">
    <div class="table">
      <table>
        <thead>
        <tr>
<!--          <th>{{$t('Version')}}</th>-->
          <th>{{$t('Quote number')}}</th>
          <th>{{$t('Quote name')}}</th>
          <th>{{$t('Issue date')}}</th>
          <th>{{$t('Deadline date')}}</th>
          <th>{{$t('Quote reply')}}</th>
          <th>{{$t('Status')}}</th>
          <th>{{$t('Operating')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
<!--          <td>V1<i data-v-ad84f3a8="" class="el-icon-arrow-down"></i></td>-->
          <td>{{detail.numbering}}</td>
          <td>
            <div class="title">{{detail.title}}</div>
          </td>
          <td>{{detail.isStatus!=='edit'&&detail.issueDate|formatDate('yyyy/MM/dd')}}</td>
          <td>{{detail.deadlineDate|formatDate('yyyy/MM/dd')}}</td>
          <td>{{detail.quoteCount||0}}</td>
          <td>
            <el-dropdown trigger="click" @command="changeStatus" v-if="['edit','issue'].includes(detail.isStatus)">
              <a href="javascript:;">
                {{detail.isStatus|quoteStatus}} <i class="el-icon-arrow-down"></i>
              </a>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{quoteId:detail.quoteId,status:'issue'}"
                                  v-if="detail.isStatus==='edit'">{{$t('Release')}}
                </el-dropdown-item>
                <el-dropdown-item :command="{quoteId:detail.quoteId,status:'end'}">
                  {{$t('Terminate')}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div v-else>{{detail.isStatus|quoteStatus}}</div>
          </td>
          <td>
            <!--修改-->
            <router-link class="btn-item m-r-8" :title="$t('Modify')"
                         :to="{name:'UserQuoteEdit',query:{id:quoteId}}"
                         v-if="['edit','issue'].includes(detail.isStatus)">
              <img src="../../../assets/images/icon/pen.png"/>
            </router-link>
            <!--查看報價-->
            <a class="btn-item m-r-8" :title="$t('View Quote')" v-if="['issue','over','end'].includes(detail.isStatus)"
               @click="showDetail">
              <img src="../../../assets/images/icon/review.png"/>
            </a>
            <!--删除-->
            <a class="btn-item" :title="$t('Delete')" @click="deleteQuote(quoteId)" :disabled="detail.quoteCount !== 0">
              <img src="../../../assets/images/icon/ashcan.png"/>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="fl-group">
      <div class="hd">{{$t('Quote result')}}</div>
      <div class="table" v-if="joinList">
        <table>
          <thead>
          <tr>
            <th>{{$t('Reply number')}}</th>
            <th>{{$t('Travel agency name')}}</th>
            <th>{{$t('Reply date')}}</th>
            <th>{{$t('Total')}}</th>
            <th>{{$t('Status')}}</th>
            <th>{{$t('Operating')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item of joinList" :key="item.joinId">
            <td>{{item.joinCode}}</td>
            <td>{{item.companyName}}</td>
            <td>{{item.updateDate|formatDate('yyyy/MM/dd')}}</td>
            <td>{{item.joinPrice}}</td>
            <td>
              <span v-if="item.joinResult==='select'">
                {{(item.joinResult||'unjoin')|dictName('quoteStatus')}}
              </span>
            </td>
            <td>
              <a class="btn-item m-r-8" :href="item.resourceUrl" :title="$t('View Quote')" target="_blank">
                <img src="../../../assets/images/icon/review.png"/>
              </a>
              <a class="btn-item m-r-8" :title="$t('Download')" @click="downloadFile(item.companyName+'報價', item.resourceUrl)">
                <img src="../../../assets/images/icon/download.png"/>
              </a>
              <!--<a class="btn-item m-r-8" href="javascript:;" :title="$t('Hide')">
                <img src="../../../assets/images/icon/hide.png"/>
              </a>-->
              <!--<a class="btn-item" href="javascript:;" :title="$t('Delete')">
                <img src="../../../assets/images/icon/ashcan.png"/>
              </a>-->
              <a class="btn-submit" @click="showConfirmModal(item)" v-if="isNotSelected">{{$t('OK')}}</a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <empty v-if="joinList&&!joinList.length" :title="$t('NoData')"/>
    </div>
    <detail-modal :detail="detail"
                  :join-list="joinList"
                  :contacts="contacts"
                  :appellation-options="appellationOptions"
                  :currency-options="currencyOptions"
                  :phone-type-options="phoneTypeOptions"
                  ref="detailModal"/>
  </div>
</template>
<script>
  import Empty from '../../../components/Empty';
  import DetailModal from './components/DetailModal';
  import {getQuoteDetails, updateQuoteStatus, deleteQuote, getQuoteJoinList, selectQuote} from '../../../api/quote';
  import {getDictList} from '../../../api/common';

  export default {
    name: 'UserQuoteDetail',
    data() {
      return {
        quoteId: '',
        detail: {},
        contacts: null,
        joinList: null,
        appellationOptions: [],
        currencyOptions: [],
        phoneTypeOptions: []
      };
    },
    computed: {
      isNotSelected() {
        if (!this.joinList) {
          return false;
        }
        return !this.joinList.find(item => item.joinResult === 'select');
      }
    },
    components: {DetailModal, Empty},
    created() {
      this.quoteId = this.$route.params.id;
      this.getDetail();
      this.getJoinList();
      this.getDicts();
    },
    methods: {
      // 获取数字字典
      getDicts() {
        // 称谓
        getDictList('appellative').then(res => {
          this.appellationOptions = res.value || [];
        });
        // 货币
        getDictList('billingType').then(res => {
          this.currencyOptions = res.value || [];
        });
        getDictList('phoneType').then(res => {
          this.phoneTypeOptions = res.value || [];
        });
      },
      // 修改发布状态
      changeStatus(command, instance) {
        const {quoteId, status} = command;
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.over'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              updateQuoteStatus(quoteId, status).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.detail.isStatus = status;
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      },
      showConfirmModal(data) {
        const h = this.$createElement;
        this.$msgbox({
          title: this.$t('Are you sure to choose this quote?'),
          message: h('div', {style: 'text-align:left;'}, [
            h('div', null, `${this.$t('Reply number')}：${data.joinCode}`),
            h('div', {style: 'padding-top:10px'}, `${this.$t('Travel agency name')}：${data.companyName}`)
          ]),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              this.selectQuote(data, instance, done);
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      },
      // 选中报价
      selectQuote(data, instance, done) {
        selectQuote(data.quoteId, data.joinId).then(res => {
          this.getDetail();
          this.getJoinList();
          instance.confirmButtonLoading = false;
          done();
          this.$message.success('提交成功!');
        }).catch(() => {
          instance.confirmButtonLoading = false;
        });
      },
      // 下载文件
      downloadFile(fileName, resourceUrl) {
        // 把http替換成https，因為http報不安全錯誤，改為https就可以了
        var url = resourceUrl.replace('http://', 'https://');
        // new Downloader({url: resourceUrl}).then(() => {
        //   // console.log('Called when download ended');
        // }).catch(() => {
        //   // console.log(err);
        // });
          fetch(url, {
            method: 'get',
            responseType: 'arraybuffer'
          })
            .then(function (res) {
              if (res.status !== 200) {
                return res.json();
              }
              return res.arrayBuffer();
            })
            .then((blobRes) => {
// 生成 Blob 对象，设置 type 等信息
              const e = new Blob([blobRes], {
                type: 'application/octet-stream',

                'Content-Disposition': 'attachment'
              });
// 将 Blob 对象转为 url
              const link = window.URL.createObjectURL(e);

              this.handleFileDownload(link, fileName + '.pdf');
            }).catch(err => {
            console.error(err);
          });
      },
      handleFileDownload (url, filename) {
// 创建 a 标签
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      },
      // 获取详情
      getDetail() {
        getQuoteDetails(this.quoteId).then(res => {
          const datas = res.value || {};
          this.detail = datas.quote;
          this.contacts = datas.contacts;
        });
      },
      // 获取参与报价列表
      getJoinList() {
        getQuoteJoinList(this.quoteId).then(res => {
          this.joinList = res.value || [];
        });
      },
      // 删除报价
      deleteQuote(quoteId) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.delete'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              deleteQuote(quoteId).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.$router.replace({name: 'UserQuote'});
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      },
      // 显示详情弹窗
      showDetail() {
        this.$refs.detailModal.show();
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    .table{
      margin-top:0;
      th,
      td{padding-left:8px;padding-right:8px;}
      .title{width:90px;}
    }
    .btn-submit{min-width:72px;padding:0 10px;line-height:20px;color:#fff;font-size:12px;background-color:#ff6f61;border-radius:4px;}
  }
  .fl-group{
    .hd{
      position:relative;margin:30px 0 20px;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
  }
</style>
