<template>
  <div>
    <div class="fl-group">
      <!--需求单号-->
      <div class="fg-hd cl">
        <slot name="editButton"></slot>
        {{$t('DemandNumber')}}：{{detail.numbering}}
      </div>
      <div class="fg-bd">
        <!--狀態-->
        <p>{{$t('Status')}}：{{detail.isStatus|quoteStatus}}</p>
        <!--配對結果-->
        <p>{{$t('MatchResult')}}：
          <span :class="{green:['over', 'end'].includes(detail.isStatus)&&detail.joinResult==='select'}">
            {{(detail.joinResult||'unjoin')|dictName('quoteStatus')}}
            </span>
        </p>
        <slot name="otherInfo"></slot>
      </div>
    </div>
    <div class="fl-group">
      <!--需求清单-->
      <div class="fg-hd">{{$t('RequirementsList')}}</div>
      <div class="fg-bd">
        <!--需求類型-->
        <p>{{$t('DemandType')}}：{{quoteTypeOptions[detail.quoteType]}}</p>
        <!--公司/機構名稱-->
        <p v-if="detail.companyName">{{$t('Company Institution Name')}}：{{detail.companyName}}</p>
        <!--活動名稱-->
        <p v-if="detail.title">{{$t('Event name')}}：{{detail.title}}</p>
        <!--出發地-->
        <p>{{$t('Departure')}}：{{detail.departure}}</p>
        <!--目的地-->
        <p>{{$t('Destination')}}：{{detail.destination}}</p>
        <!--預計出發日期-->
        <p>{{$t('Expected departure date')}}：{{detail.departureDate|formatDate('yyyy-MM-dd')}}</p>
        <!--期望出行天數-->
        <p>{{$t('Expected journey days')}}：{{detail.traveledDay}}{{$t('Day')}}</p>
        <!--預計參加人數-->
        <p>{{$t('Expected number of participants')}}：
          {{detail.adultJoinPeople}}({{$t('Adult')}})、{{detail.childJoinPeople}}({{$t('Child')}})
        </p>
        <!--預算貨幣-->
        <p>{{$t('BudgetCurrency')}}：{{detail.billingType|dictName(currencyOptions)}}</p>
        <!--預算類型-->
        <p>{{$t('Budget type')}}：{{detail.budgetType|dictName(budgetTypeOptions)}}</p>
        <!--預算-->
        <p>{{$t('Budget')}}：{{detail.expectedPrice}}</p>
        <!--出遊性質(可多選)-->
        <p>{{$t('Nature of travel')}}：{{detail.travelNatureName}}{{detail.otherNature?`;${detail.otherNature}`:''}}</p>
        <!--報價截止日期-->
        <p>{{$t('Quote deadline')}}：{{detail.deadlineDate|formatDate('yyyy-MM-dd')}}</p>
      </div>
    </div>
    <div class="fl-group" v-if="detail.memo">
      <!--附加說明或需求-->
      <div class="fg-hd">{{$t('Additional instructions or requirements')}}</div>
      <div class="fg-bd">{{detail.memo}}</div>
    </div>
    <div class="fl-group" v-if="contacts">
      <!--聯絡人資料-->
      <div class="fg-hd">{{$t('ContactInformation')}}</div>
      <div class="fg-bd">
        <p>{{$t('Appellation')}}：{{contactInfo.isCalled|dictName(appellationOptions)}}</p>
        <p>{{$t('Contact Name')}}：{{contactInfo.userName}}</p>
        <p v-if="contactInfo.position">{{$t('Position')}}：{{contactInfo.position}}</p>
        <p>{{$t('Phone')}}：({{contactInfo.phonePrefix}}) {{contactInfo.userPhone}}</p>
        <p>{{$t('Email')}}：{{contactInfo.userEmail}}</p>
        <p v-for="(item,index) of otherContacts" :key="index">
          {{item.phoneType|dictName(phoneTypeOptions)}}：({{item.phonePrefix}}) {{item.userPhone}}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'QuoteDetail',
    props: {
      detail: Object,
      contacts: Array,
      appellationOptions: Array,
      currencyOptions: Array,
      phoneTypeOptions: Array
    },
    data() {
      return {
        quoteTypeOptions: {personal: this.$t('Personal customization'), group: this.$t('Group Company customization')},
        budgetTypeOptions: [
          {code: 'perCapita', name: this.$t('Per capita')},
          {code: 'lumpSum', name: this.$t('Total')}
        ]
      };
    },
    computed: {
      contactInfo() {
        return (this.contacts || []).find(item => item.userName) || {};
      },
      otherContacts() {
        return (this.contacts || []).filter(item => !item.userName);
      }
    }
  };
</script>
<style scoped lang="less">
  .fl-group{
    .fg-hd{
      position:relative;margin:30px 0 20px;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .fg-bd{
      padding:20px;font-size:16px;background-color:#f4f4f4;border-radius:4px;
      p{
        margin-bottom:10px;
        &:last-child{margin-bottom:0;}
      }
    }
    .green{color:#00b680;}
  }
</style>
