<template>
  <div class="main">
    <h1 class="h1">{{$t('Quote')}}</h1>
    <tabs v-model="activeName">
      <tab-pane :label="$t('All quotes')" name="all"></tab-pane>
    </tabs>
    <div class="actions">
      <button class="button button-primary" @click="$router.push({name:'UserQuoteEdit'})">
        <i class="icons i-plus"></i>
        <span class="vam">{{$t('I want to quote')}}</span>
      </button>
    </div>
    <div class="table">
      <table>
        <thead>
        <tr>
<!--          <th>{{$t('Version')}}</th>-->
          <th>{{$t('Quote number')}}</th>
          <th>{{$t('Quote name')}}</th>
          <th class="is-sortable" :class="sortClassName('startDate')" @click="sortable('date')">
            <span class="vam">{{$t('Issue date')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
              </span>
          </th>
          <th class="is-sortable" :class="sortClassName('endDate')" @click="sortable('date')">
            <span class="vam">{{$t('Deadline date')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
              </span>
          </th>
          <th class="is-sortable" :class="sortClassName('reply')" @click="sortable('date')">
            <span class="vam">{{$t('Quote reply')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
              </span>
          </th>
          <th class="is-sortable" :class="sortClassName('status')" @click="sortable('browse')">
            <span class="vam">{{$t('Status')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
              </span>
          </th>
          <th>{{$t('Operating')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) of list" :key="item.quoteId">
<!--          <td class="relative">-->
<!--            V1-->
<!--            <i class="el-icon-arrow-down"></i>-->
<!--            <i class="icons i-new"></i>-->
<!--          </td>-->
          <td>{{item.numbering}}</td>
          <td>
            <div class="title">{{item.title}}</div>
          </td>
          <td>{{item.isStatus!=='edit'&&item.issueDate|formatDate('yyyy/MM/dd')}}</td>
          <td>{{item.deadlineDate|formatDate('yyyy/MM/dd')}}</td>
          <td>{{item.quoteCount}}</td>
          <td>
            <el-dropdown trigger="click" @command="changeStatus" v-if="['edit','issue'].includes(item.isStatus)">
              <a href="javascript:;">
                {{item.isStatus|quoteStatus}} <i class="el-icon-arrow-down"></i>
              </a>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{index,quoteId:item.quoteId,status:'issue',message:$t('confirm.release')}"
                                  v-if="item.isStatus==='edit'">{{$t('Release')}}
                </el-dropdown-item>
                <el-dropdown-item :command="{index,quoteId:item.quoteId,status:'end'}">
                  {{$t('Terminate')}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div v-else>{{item.isStatus|quoteStatus}}</div>
          </td>
          <td>
            <router-link class="btn-item m-r-8"
                         target="_blank"
                         :title="$t('Modify')"
                         :to="{name:'UserQuoteEdit',query:{id:item.quoteId}}"
                         v-if="['edit','issue'].includes(item.isStatus)">
              <img src="../../../assets/images/icon/pen.png"/>
            </router-link>
            <router-link class="btn-item m-r-8"
                         target="_blank"
                         :title="$t('View Quote')"
                         :to="{name:'UserQuoteDetail',params:{id:item.quoteId}}"
                         v-if="['issue','over','end'].includes(item.isStatus)">
              <img src="../../../assets/images/icon/review.png"/>
            </router-link>
            <a class="btn-item" href="javascript:;" :title="$t('Delete')" @click="deleteQuote(index,item.quoteId)" :disabled="item.quoteCount !== 0">
              <img src="../../../assets/images/icon/ashcan.png"/>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <empty v-if="isEmpty" :title="$t('NoData')"/>
    <el-pagination class="pages"
                   background
                   layout="sizes, prev, pager, next"
                   :page-count="totalPages"
                   @current-change="changePage"
                   @size-change="handleSizeChange"
                   :hide-on-single-page="false">
    </el-pagination>
  </div>
</template>
<script>
  import Tabs from '../../../components/Tabs/Tabs';
  import TabPane from '../../../components/Tabs/TabPane';
  import Empty from '../../../components/Empty';
  import table from '../../../mixins/table';
  import {scrollToY} from '../../../utils';
  import {getQuoteList, updateQuoteStatus, deleteQuote} from '../../../api/quote';

  export default {
    name: 'UserQuote',
    data() {
      return {
        activeName: 'all',
        perPage: 10,
        list: [],
        totalPages: 1,
        isEmpty: false
      };
    },
    components: {Empty, Tabs, TabPane},
    mixins: [table],
    created() {
      this.getList();
    },
    methods: {
      // 获取消息列表
      getList(page = 1) {
        const data = {page, perPage: this.perPage};
        getQuoteList(data).then(res => {
          const {value} = res;
          const list = value.list || [];
          this.list = list;
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
          this.$nextTick(() => {
            scrollToY();
          });
        });
      },
      changePage(page) {
        this.getList(page);
      },
      handleSizeChange(val) {
        this.perPage = val;
        this.getList();
      },
      // 修改发布状态
      changeStatus(command, instance) {
        const {index, quoteId, status, message = this.$t('confirm.over')} = command;
        this.$msgbox({
          title: this.$t('Prompt'),
          message,
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              updateQuoteStatus(quoteId, status).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.list[index].isStatus = status;
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      },
      // 删除报价
      deleteQuote(index, quoteId) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.delete'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              deleteQuote(quoteId).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.list.splice(index, 1);
                this.$message.success('刪除成功!');
                if (!this.list.length) {
                  this.getList();
                }
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    .actions{margin-top:20px;}
    .table{
      th,
      td{padding-left:8px;padding-right:8px;}
      .title{width:90px;}
    }
    .i-plus{width:16px;height:16px;margin-right:4px;vertical-align:middle;background-image:url(../../../assets/images/icon/plus-circle.png);}
    .i-new{position:absolute;top:6px;right:10px;width:14px;height:8px;background-image:url(../../../assets/images/icon/new.png);}
  }
</style>
