<template>
  <modal :title="$t('SeeDetails')" width="960px" :is-show="isShow" @close-modal="hide">
    <quote-detail class="detail-infos"
                  :detail="detail"
                  :contacts="contacts"
                  :appellation-options="appellationOptions"
                  :currency-options="currencyOptions"
                  :phone-type-options="phoneTypeOptions">
      <div slot="otherInfo" v-if="detail.joinResult==='select'">
        <!--回覆編號-->
        <p>{{$t('Reply number')}}：{{selectedQuote.joinCode}}</p>
        <!--旅行社名稱-->
        <p>{{$t('Travel agency name')}}：{{selectedQuote.companyName}}</p>
        <!--報價附件-->
        <p>
          {{$t('QuoteAttachment')}}：
          <a class="btn-link" :href="selectedQuote.resourceUrl" target="_blank">{{$t('View Quote')}}</a>
        </p>
      </div>
      <!--编辑-->
      <router-link class="btn-edit" :to="{name:'UserQuoteEdit',query:{id:detail.quoteId}}" slot="editButton"
                   v-if="['edit','issue'].includes(detail.isStatus)">
        {{$t('Edit')}}
      </router-link>
    </quote-detail>
  </modal>
</template>
<script>
  import Modal from '../../../../components/Modal';
  import QuoteDetail from '../../../../components/QuoteDetail';

  export default {
    name: 'DetailModal',
    props: {
      detail: Object,
      joinList: Array,
      contacts: Array,
      appellationOptions: Array,
      currencyOptions: Array,
      phoneTypeOptions: Array
    },
    data() {
      return {
        isShow: false
      };
    },
    computed: {
      selectedQuote() {
        return (this.joinList || []).find(item => item.joinResult === 'select') || {};
      }
    },
    components: {QuoteDetail, Modal},
    methods: {
      show() {
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      }
    }
  };
</script>
<style scoped lang="less">
  .detail-infos{
    padding:0 30px 30px;text-align:left;
    .btn-link{text-decoration:underline;color:#ff6f61;}
    .btn-edit{float:right;margin-top:3px;margin-left:8px;padding:0 8px;line-height:28px;color:#1890ff;font-size:14px;border:1px solid #1890ff;border-radius:4px;}
  }
</style>
